<template>
    <div class="full flex flex-column flex-align flex-center">
        <div class="loading" v-if="loading">
            <spinner />
        </div>
        <template v-else>
            <div class="banner"></div>
            <div class="slash"></div>
            <div class="picker-wrapper">
                <div class="client-logo" v-if="theme">
                    <img :src="theme.application_banner" :alt="theme.application_name" />
                </div>
                <div class="class-picker card">
                    <template v-if="error_msg">
                        <div class="class-picker-explanation">
                            <h3 class="center">Error</h3>
                            <h4 class="center">{{ error_msg }}</h4>
                            <div class="button-group flex-center">
                                <!-- <div class="button" @click="close()">Close Window</div> -->
                            </div>
                        </div>
                    </template>
                    <template v-else-if="expired">
                        <div class="class-picker-explanation">
                            <h3 class="center">Session Expired</h3>
                            <h4 class="center">Unfortunately this login session has expired. Please re-launch and try again.</h4>
                            <div class="button-group flex-center">
                                <!-- <div class="button" @click="close()">Close Window</div> -->
                            </div>
                        </div>
                    </template>
                    <template v-else-if="no_classes">
                        <div class="class-picker-explanation">
                            <h3 class="center">No Classes Found</h3>
                            <h4 class="center">It looks like you are not enrolled in any classes. {{ theme ? `${theme.application_name} requires that you` : 'You are required to' }} select a class when launching.</h4>
                            <div class="button-group flex-center">
                                <!-- <div class="button" @click="close()">Close Window</div> -->
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="class-picker-explanation">
                            <h3 class="center" v-if="formatName(person)">Welcome, {{ formatName(person) }}!</h3>
                            <h3 class="center" v-else>Choose a Class</h3>
                            <h4 class="center">Please select the class {{ district ? `at ${district.name}`: '' }} you would like to launch {{ theme ? `into ${theme.application_name} with` : 'with' }}.</h4>
                        </div>
                        <div class="classes">
                            <div class="class flex flex-row flex-align" :class="{ 'disabled': cls.state === 'inactive' }" v-for="cls of classes" :key="cls.id" @click="go(cls.id)">
                                <div class="class-name ff text-overflow">
                                    {{ cls.name }}
                                </div>
                                <ArrowRight class="icon" width="14" height="14" stroke-width="2.5" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { ArrowRight } from '@epiphany/iconoir';

    const ROBLOX_PRODUCTION_APPLICATION_ID = 'fc4fbea3-12fd-4616-8c34-d9f80c93807f';

    export default {
        name: 'SSOSelectContext',
        components: {
            ArrowRight
        },
        data(){
            return {
                classes: [],
                expired: false,
                loading: true,
                error_msg: null,
                theme: null,
                person: null,
                district: null
            };
        },
        computed: {
            no_classes() {
                return this.classes.length === 0;
            }
        },
        methods: {
            formatName(person){
                if (!person) {
                    return null;
                } else if (person.display_name) {
                    return person.display_name;
                } else if (person.first_name && person.last_name) {
                    return `${person.first_name} ${person.last_name}`;
                } else if (person.first_name) {
                    return person.first_name;
                } else {
                    return null
                }
            },
            go(class_id) {
                // Make a hidden form
                const form = document.createElement('form');

                form.method = 'POST';
                form.action = `/api/authentication/sso/${this.$route.query.login_hint}/continue`;
                form.style.display = 'none';

                // Build a params object for the form
                const params = {
                    class_id
                };

                // Loop through the params and add them to the form
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = params[key];
                        form.appendChild(hiddenField);
                    }
                }

                // Add the form to the document
                document.body.appendChild(form);

                // Submit the form
                form.submit();
            },
            close() {
                window.close();
            }
        },
        async created(){
            if (!this.$route.query.login_hint) {
                // Set the error message
                this.error_msg = 'No login hint provided';
                // Hide the loading spinner
                this.loading = false;
                // exit
                return;
            }

            try {
                const { $data: theme } = await this.$http.get(`/marketplace/${ROBLOX_PRODUCTION_APPLICATION_ID}`).catch(err => {
                    console.error('Failed to get theme', err);
                });

                if (theme) {
                    this.theme = {
                        application_name: theme.name,
                        application_banner: theme.banner_url
                    }

                    // Set the favicon
                    document.querySelector('link[rel="icon"]').href = theme.icon_url;
                    // Set page title
                    document.title = `Login - Class Selection | ${theme.name}`;
                }

                const { $data: data } = await this.$http.get(`/sso/${this.$route.query.login_hint}/continue`, { baseURL: '/api/authentication' }).catch(err => {
                    if (err.$error === 'You must be logged in to access this endpoint.') {
                        this.error_msg = 'Authentication error. Please re-launch and try again.'
                    } else {
                        // Set the error message
                        this.error_msg = 'An error occurred while loading your classes. Please try again later.';
                    }
                });

                // Show error if no data
                if (!data) {
                    // Hide the loading spinner
                    this.loading = false;
                    return;
                };

                if (data.person) {
                    this.person = data.person;
                }

                if (data.district) {
                    this.district = data.district;
                }

                // Sort the classes by name and and save them
                this.classes = data.classes.sort((a, b) => {
                    if (a.state === 'inactive') {
                        return 1;
                    } else {
                        return a.name.localeCompare(b.name);
                    }
                });

                // Set a timeout to expire the session after 5 minutes
                // Get the time the sso seesion was created
                const created = new Date(data.created_date);
                // Get the current time
                const now = new Date();
                // Get the difference between the two
                const diff = now - created;
                // Get the timeout value remaining
                const timeout = 300000 - diff;

                // Set the timeout
                setTimeout(() => {
                    // Mark this session as expired
                    this.expired = true;
                }, timeout);

                if (this.classes.length === 1) {
                    this.go(this.classes[0].id);
                } else {
                    // Hide the loading spinner
                    this.loading = false;
                }
            } catch(error) {
                console.error('Failed to load classes', error);
                this.error_msg = 'An unknown error occurred while logging you in. Please try again later.';
                // Hide the loading spinner
                this.loading = false;
            }

        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    // Dark Mode Colors
    // 00dp: #121212
    // 01dp: #1E1E1E
    // 02dp: #222222
    // 03dp: #252525
    // 04dp: #262626
    // 06dp: #2C2C2C
    // 08dp: #2E2E2E
    // 12dp: #333333
    // 16dp: #353535
    // 24dp: #383838

    .client-logo {
        // position: absolute;
        // top: 20px;
        // left: 20px;
        margin-bottom: 20px;

        img {
            max-width: 200px;
            height: auto;
        }
    }

    .full {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #121212;
    }

    .slash {
        background: #1E1E1E;
        position: absolute;
        left: -15%;
        top: 0;
        bottom: 0;
        width: 60%;
        transform: skewX(-15deg);
        box-shadow: 20px 8px 15px -2px rgba(0, 0, 0, 0.3);
    }

    @keyframes repeating {
        0% {
            background-position: 0 0;
        }
        100% {
            background-position: 883px 0;
        }
    }

    .banner {
        background: url('../../public/robloxbg.png') repeat;
        // changing this breaks the animation
        // background-size: contain;
        position: absolute;
        top: -200px;
        right: -200px;
        bottom: -200px;
        left: -200px;
        // opacity: 0.65;
        transform: rotate(15deg);

        animation: repeating 22s linear infinite;
    }

    .picker-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        position: absolute;
        top: 0;
        right: 57.5%;
        bottom: 0;
        left: 0;
        padding: 10vh 0;
    }

    .class-picker {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 450px;
        background: #222222;
        border-color: #222222;
        border-radius: 6px;
        box-shadow: 0 1px 4px 0 fade(@black, 10%);
        overflow: hidden;

        h4 {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 14px;
            color: @grey;
            padding: 0 30px;
        }
    }

    h3, h4 {
        color: @white;
        margin-top: 0;
        margin-bottom: 10px;
    }

    .class-picker-explanation
    {
        padding: 20px;
        // background: #252525;
    }

    .classes
    {
        padding: 10px;
        border-top: 1px solid #333333;
        overflow-y: auto;
        border-radius: 6px;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .class
    {
        padding: 15px 12px;
        border-radius: 6px;

        &-name {
            font-size: 14px;
            font-weight: 500;
            color: @white;
        }
        
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            cursor: pointer;
            background: #2C2C2C;
        }

        .icon {
            margin-left: 10px;
            color: @grey;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            text-decoration: line-through;
        }
    }

    .spinner
    {
        color: @base;
    }
</style>