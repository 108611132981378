<template>
    <div class="login flex flex-column flex-center">
        <h1>400</h1>
        <h3>There was an error signing you in.</h3>
        <h4>{{error}}</h4>
        <div class="flex flex-align">
            <!-- <div class="button">
                Return to Application
            </div> -->
            <div class="button white has-icon" @click="copy" :class="{disabled: copied}" v-if="payload">
                <svg width="16px" height="16px" stroke-width="2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor">
                    <path d="M19.4 20H9.6a.6.6 0 01-.6-.6V9.6a.6.6 0 01.6-.6h9.8a.6.6 0 01.6.6v9.8a.6.6 0 01-.6.6z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M15 9V4.6a.6.6 0 00-.6-.6H4.6a.6.6 0 00-.6.6v9.8a.6.6 0 00.6.6H9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div>{{copied ? 'Copied to Clipboard' : 'Copy Error Details'}}</div>
            </div>
        </div>
        <div class="ff"></div>
        <div class="edlink-brand"></div>
    </div>
</template>

<script>
    export default {
        name: 'Error',
        data() {
            return {
                payload: null,
                copied: false,
                errors: {
                    INVALID_SOURCE_CONFIGURATION: 'Something about the way you configured your source is incorrect.',
                    INVALID_INTEGRATION_ID: 'There is no integration with the ID you provided.',
                    INVALID_INTEGRATION_STATE: 'There is an integration between your organization and this application, but it is not currently active.',
                    NO_CLIENT_ID: 'You need to provide a valid `client_id` for your application in order to initiate the SSO process.',
                    INVALID_STATE: 'You need to provide a valid `state` parameter in order to initiate the SSO process.',
                    NO_LTI_CONSUMER_KEY: 'You have not properly configured your LTI 1.1 consumer key.',
                    INVALID_LTI_CLIENT_ID: 'You need to provide a valid LTI 1.3 issuer in order to initiate the SSO process.',
                    INVALID_LTI_ISSUER: 'You need to provide a valid LTI 1.3 issuer in order to initiate the SSO process.',
                    INVALID_LTI_CLIENT_ID_ISSUER_PAIR: 'You need to provide a valid LTI 1.3 issuer and client ID in order to initiate the SSO process. The combination provided does not match one that we recognize.',
                    INVALID_LTI_JWT: 'Something about your LTI 1.3 launch JWT was invalid.',
                    INVALID_LTI_RETURN_URL: 'The LTI return URL passed to Edlink from the LMS was missing or invalid.',
                    NO_REDIRECT_URI: 'You need to include a redirect URI for Edlink to send the user back to after they sign in.',
                    INVALID_REDIRECT_URI: 'The redirect URI you provided does not match one you specified in your redirect URI settings for the requested application.',
                    NO_PRIMARY_REDIRECT_URI: 'This means that a redirect URI has not been configured for the connected application and so Edlink does not know where to send you after authentication. Please contact the developer of the connected applicaton to resolve this issue.',
                    INVALID_ALTERNATIVE_LOGIN_URL: 'This means that at least one of the provided alternative login URLs does not match the ones configured for the connected application and so Edlink does not know where to send you after authentication. Please contact the developer of the connected applicaton to resolve this issue.',
                    NO_ALTERNATIVE_LOGIN_URLS: 'This means that there are no alternative login URLs configured for the connected application and so Edlink does not know where to send you after authentication. Please contact the developer of the connected applicaton to resolve this issue.',
                    NO_SSO_PROVIDER: 'This means that the provider of the connected source, and it\'s linked sources, do not support SSO. Please link or connect a source with a provider that supports SSO and try again.',
                    CLASS_NOT_SHARED: 'The class you are launching from is not shared with this application. Please try launching from a different class or contact your administrator.',
                    PERSON_NOT_SHARED: 'This means that the person you are attempting to authenticate as is not shared with the connected application because they are masked by a sharing rule. Please reconfiure your sharing rules (on the Edlink dashboard) to include this person and try again.',
                    NON_GENERIC_SSO_PROVIDER: 'This means we need more information than just the provider to authenticate you. Please contact the developer of the connected app to resolve this issue.'
                }
            }
        },
        created() {
            if(this.$route.query.payload){
                this.payload = this.$route.query.payload;
            }
        },
        computed: {
            error() {
                if(this.errors.hasOwnProperty(this.$route.query.error)) {
                    return `${this.errors[this.$route.query.error]} ${this.details ? this.details : ''}`.trim();
                }

                return `We are not exactly sure why this happened${this.details ? ` but we thought this might help: ${this.details}` : ''}.`;
            },
            details(){
                if(this.$route.query.details){
                    return this.$route.query.details;
                }
            }
        },
        methods: {
            copy(){
                if(this.payload){
                    navigator.clipboard.writeText(this.payload);
                    this.copied = true;

                    setTimeout(() => {
                        this.copied = false;
                    }, 3000);
                }
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .login
    {
        width: 100vw;
        height: 100vh;
        background: @f;
        overflow: hidden;
        padding: 100px;
        align-items: flex-start;
        background: url('~@/assets/icons/grey/dot.svg') 0 0 repeat;
        background-size: 20px 20px;
    }

    h1
    {
        font-size: 90px;
        margin: 0 0 15px;
        font-weight: 500;
        letter-spacing: -0.04rem;
        font-family: @monospace;
        color: @base;
    }
    
    .options .text-button
    {
        margin: 0px 5px;
    }

    h3
    {
        line-height: 48px;
        font-weight: 500;
        font-size: 40px;
        margin-top: 20px;
    }

    h4
    {
        margin: 20px 0 30px;
        color: @black;
        font-size: 20px;
        line-height: 28px;
        max-width: 500px;
        font-weight: 500;
    }

    .button.white.has-icon
    {
        margin-left: 15px;

        &:first-child
        {
            margin-left: 0;
        }
    }

    .edlink-brand
    {
        height: 30px;
        display: block;
        width: 118px;
        background: url('~@/assets/edlink-diamonds-black.svg') 0 center no-repeat;
        background-size: auto 25px;
        color: transparent;
        line-height: 30px;
    }
</style>