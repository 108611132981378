<template>
    <div class="consent flex flex-column flex-center flex-align">
        <div class="logo"></div>
        <template v-if="error">
            <h3>Consent Error</h3>
            <h4 v-if="consent">We're not sure what went wrong, but please close this window and try again.</h4>
            <h4 v-else>Consent was rejected. Please close this window and try again later.</h4>
        </template>
        <template v-else>
            <h3>Completing Consent Workflow</h3>
        </template>
    </div>
</template>

<script>
    const origin = process.env.NODE_ENV === 'production' ? 'https://ed.link' : 'http://localhost:8080';

    export default {
        name: 'SSOConsent',
        data(){
            return {
                error: false,
                consent: false
            };
        },
        created(){
            const {state, admin_consent, tenant} = this.$route.query;

            //TODO Admin consent === false

            if(state && tenant && admin_consent){
                if(window.opener){
                    window.opener.postMessage({state, tenant_id: tenant}, origin);
                }else{
                    this.error = true;
                }
            }else{
                this.error = true;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .consent
    {
        width: 100vw;
        height: 100vh;
        background: @f;
        overflow: hidden;
    }

    .logo
    {
        margin-bottom: 30px;
        width: 80px;
        height: 80px;
        background: url('https://ed.link/edlink-diamonds-black.svg') center center no-repeat;
        background-size: 60px auto;
    }

    h4
    {
        max-width: 350px;
        text-align: center;
    }
</style>