import Vue from 'vue';
import axios from 'axios';
import Router from 'vue-router';
import Toasted from 'vue-toasted';

import Application from './Application.vue';
import SSOError from './views/SSOError.vue';
import SSOAdministrator from './views/SSOAdministrator.vue';
import SSOLogin from './views/SSOLogin.vue';
import SSOConsent from './views/SSOConsent.vue';
import SSOLTISections from './views/SSOLTISections.vue';
import SSOSelectContext from './views/SSOSelectContext.vue';

import Spinner from './components/Spinner.vue';

Vue.config.productionTip = false;

Vue.http = Vue.prototype.$http = axios;

Vue.http.defaults.baseURL = '/api/v1';

Vue.http.interceptors.response.use(
    (response) => {
        if (!response.data.$data) {
            console.warn(`Call to ${response.config.url} did not include $data.`);
        }

        return response.data;
    },
    (error) => {
        if (axios.isCancel(error)) {
            return Promise.reject();
        }

        //TODO Handle this better
        if (!error.response.data.$error) {
            console.warn(`Error from ${error.config.url} did not include $error.`);
        }

        return Promise.reject(error.response.data);
    }
);

require('./assets/less/global.less');

Vue.component('spinner', Spinner);

Vue.use(Toasted, {
    position: 'top-center',
    duration: 5000,
    containerClass: 'toast-container',
    className: 'toast',
    theme: 'bubble'
});

Vue.use(Router);

const router = new Router({
    mode: 'history',
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: '/sso/administrator',
            name: 'sso.administrator',
            component: SSOAdministrator
        },
        {
            path: '/sso/consent',
            name: 'sso.consent',
            component: SSOConsent
        },
        {
            path: '/sso/login',
            name: 'sso.login',
            component: SSOLogin
        },
        {
            path: '/sso/integrations/:integration_id',
            name: 'sso.login.integration',
            component: SSOLogin
        },
        {
            path: '/sso/integrations/:integration_id/error',
            name: 'sso.login.integration.error',
            component: SSOError
        },
        {
            path: '/sso/login/:provider',
            name: 'sso.login.provider',
            component: SSOLogin
        },
        {
            path: '/sso/lti/sections',
            name: 'sso.lti.sections',
            component: SSOLTISections
        },
        {
            path: '/sso/roblox',
            name: 'sso.context',
            component: SSOSelectContext
        },
        {
            path: '/sso/error',
            name: 'sso.error',
            component: SSOError
        },
        {
            path: '/sso/error/:application_id',
            name: 'sso.error.application',
            component: SSOError
        }
    ]
});

new Vue({ router, render: (h) => h(Application) }).$mount('#app');
