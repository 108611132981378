<template>
    <svg class="spinner" viewBox="0 0 50 50" :class="classes">
        <g class="rotation">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </g>
        <g class="done">
            <circle class="check-bubble" cx="25" cy="25" r="22" fill="none"></circle>
            <path d="M10 26L18 34L38 14" class="check" stroke-linecap="round" stroke-linejoin="round" stroke-width="5" fill="none" />
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'Spinner',
        props: {
            classes: Object
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .spinner
    {
        width: 20px;
        height: 20px;
        z-index: 2;
        color: @base;

        .rotation
        {
            animation: rotate 2s linear infinite;
            transition: all ease 0.25s;
            transform-origin: center;
            transform-box: fill-box;
        }

        .path
        {
            stroke: @base;
            stroke-linecap: round;
            animation: dash 1.5s ease-in-out infinite;
        }

        &.slow
        {
            .path
            {
                stroke: @grey;
                animation: none;
                stroke-dasharray: 90, 150;
                stroke-dashoffset: 0;
            }
        }

        .done
        {
            transform: scale(0);
            transition: all ease 0.25s;
            transform-origin: center;
            transform-box: fill-box;
        }

        .check
        {
            stroke: @f;
            stroke-linecap: round;
            transform: scale(0.7) translate(12px, 12px);
        }

        .check-bubble
        {
            fill: @base;
        }

        &.complete
        {
            .done
            {
                transform: scale(1);
            }

            .rotation
            {
                transform: scale(0);
            }
        }
    }

    @keyframes rotate {
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }

        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
</style>
