<template>
    <div class="full flex flex-column flex-align flex-center">
        <div class="loading" v-if="loading">
            <spinner />
        </div>
        <div class="class-picker card" v-else>
            <template v-if="expired">
                <div class="class-picker-explanation">
                    <h3 class="center">Session Expired</h3>
                    <h4 class="center">Unfortunately this login session has expired. Please re-launch from your LMS and try again.</h4>
                    <div class="button-group flex-center">
                        <!-- <div class="button" @click="close()">Close Window</div> -->
                    </div>
                </div>
            </template>
            <template v-else-if="error_msg">
                <div class="class-picker-explanation">
                    <h3 class="center">Error</h3>
                    <h4 class="center">{{ error_msg }}</h4>
                    <div class="button-group flex-center">
                        <!-- <div class="button" @click="close()">Close Window</div> -->
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="class-picker-explanation">
                    <h3 class="center">Choose a Section</h3>
                    <h4 class="center">It looks like you've launched from a class with multiple sections. Please select the one you would like to launch into.</h4>
                </div>
                <div class="classes">
                    <div class="class flex flex-row flex-align" v-for="cls of classes" :key="cls.id" @click="go(cls.id)">
                        <div class="class-name ff text-overflow">
                            {{ cls.name }}
                        </div>
                        <ArrowRight class="icon" width="14" height="14" stroke-width="2.5" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import { ArrowRight } from '@epiphany/iconoir';

    export default {
        name: 'SSOLTISections',
        components: {
            ArrowRight
        },
        data(){
            return {
                classes: [],
                expired: false,
                loading: true,
                error_msg: null
            };
        },
        computed: {
        },
        methods: {
            go(class_id) {
                // Make a hidden form
                const form = document.createElement('form');

                form.method = 'POST';
                form.action = `/api/authentication/sso/${this.$route.query.login_hint}/continue`;
                form.style.display = 'none';

                // Build a params object for the form
                const params = {
                    class_id
                };

                // Loop through the params and add them to the form
                for (const key in params) {
                    if (params.hasOwnProperty(key)) {
                        const hiddenField = document.createElement('input');
                        hiddenField.type = 'hidden';
                        hiddenField.name = key;
                        hiddenField.value = params[key];
                        form.appendChild(hiddenField);
                    }
                }

                // Add the form to the document
                document.body.appendChild(form);

                // Submit the form
                form.submit();
            },
            close() {
                window.close();
            }
        },
        created(){
            if (!this.$route.query.login_hint) {
                // Set the error message
                this.error_msg = 'No login hint provided';
                // Hide the loading spinner
                this.loading = false;
                // exit
                return;
            }

            this.$http.get(`/sso/${this.$route.query.login_hint}/continue`, { baseURL: '/api/authentication' }).then(response => {
                const { $data: data } = response;

                // Sort the classes by name and save them
                this.classes = data.classes.sort((a, b) => a.name.localeCompare(b.name));

                // Set a timeout to expire the session after 5 minutes
                // Get the time the sso seesion was created
                const created = new Date(data.created_date);
                // Get the current time
                const now = new Date();
                // Get the difference between the two
                const diff = now - created;
                // Get the timeout value remaining
                const timeout = 300000 - diff;

                // Set the timeout
                setTimeout(() => {
                    // Mark this session as expired
                    this.expired = true;
                }, timeout);

                // Hide the loading spinner
                this.loading = false;
            }).catch(err => {
                if (err.$error === 'You must be logged in to access this endpoint.') {
                    this.error_msg = 'Authentication error. Please re-launch from your LMS.'
                } else {
                    // Set the error message
                    this.error_msg = 'An error occurred while loading your classes. Please try again later.';
                }
                // Hide the loading spinner
                this.loading = false;
            })
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/mixins";

    .full {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }

    .class-picker {
        width: 100%;
        max-width: 450px;
        background: @white;
        border-radius: 6px;
        box-shadow: 0 1px 4px 0 fade(@black, 10%);

        h4 {
            margin-top: 5px;
            margin-bottom: 0;
            font-size: 14px;
            color: @grey;
            padding: 0 30px;
        }
    }

    .class-picker-explanation
    {
        padding: 20px;
    }

    .classes
    {
        padding: 10px;
        border-top: 1px solid @e4;

        &:first-child
        {
            margin-top: 0;
        }
    }

    .class
    {
        padding: 15px 12px;
        border-radius: 6px;

        &-name {
            font-size: 14px;
            font-weight: 500;
            color: @black;
        }
        
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            cursor: pointer;
            background: fade(@black, 5%);
        }

        .icon {
            margin-left: 10px;
            color: @grey;
        }
    }

    .spinner
    {
        color: @base;
    }
</style>