<template>
    <div class="administrator flex flex-column flex-center flex-align">
        <div class="logo"></div>
        <template v-if="error">
            <h3>Sign In Error</h3>
            <h4>We're not sure what went wrong, but please close this window and try again.</h4>
        </template>
        <template v-else>
            <h3>Completing Sign In</h3>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Administrator',
        data(){
            return {
                error: false
            };
        },
        created(){
            const {state, code} = this.$route.query;

            if(state && code){
                if(window.opener){
                    window.opener.postMessage({code, state}, process.env.NODE_ENV === 'production' ? 'https://ed.link' : '*');
                }else{
                    this.error = true;
                }
            }else{
                this.error = true;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "~@/assets/less/variables";

    .administrator
    {
        width: 100vw;
        height: 100vh;
        background: @f;
        overflow: hidden;
    }

    .logo
    {
        margin-bottom: 30px;
        width: 80px;
        height: 80px;
        background: url('https://ed.link/edlink-diamonds-black.svg') center center no-repeat;
        background-size: 60px auto;
    }

    h4
    {
        max-width: 350px;
        text-align: center;
    }
</style>