<template>
    <div class="application">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'Application'
    }
</script>

<style lang="less">
    @import "~@/assets/less/variables";
    @import "~@/assets/less/global";
</style>
