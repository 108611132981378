import { render, staticRenderFns } from "./SSOLogin.vue?vue&type=template&id=28dc7da7&scoped=true"
import script from "./SSOLogin.vue?vue&type=script&lang=js"
export * from "./SSOLogin.vue?vue&type=script&lang=js"
import style0 from "./SSOLogin.vue?vue&type=style&index=0&id=28dc7da7&prod&lang=less"
import style1 from "./SSOLogin.vue?vue&type=style&index=1&id=28dc7da7&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28dc7da7",
  null
  
)

export default component.exports